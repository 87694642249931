<template>
    <div>
        <NavComp/>
            <div class="container">
                <div class="header"><h1>Lunar Calender</h1></div>
                <!-- Today -->
                <div class="text card">
                    <h4>Date: <span v-if="astroData[0]">{{astroData[0].date}}</span><span v-else>loading...</span></h4>
                    <h4>Sunrise: <span v-if="astroData[0]">{{astroData[0].sunrise}}</span><span v-else>loading...</span></h4>
                    <h4>Sunset: <span v-if="astroData[0]">{{astroData[0].sunset}}</span><span v-else>loading...</span></h4>
                    <h4>Moonrise: <span v-if="astroData[0]">{{astroData[0].moonrise}}</span><span v-else>loading...</span></h4>
                    <h4>Moonset: <span v-if="astroData[0]">{{astroData[0].moonset}}</span><span v-else>loading...</span></h4>
                    <h4>Moon Phase: <span v-if="astroData[0]">{{astroData[0].moon_phase}}</span><span v-else>loading...</span>
                        <span v-if="astroData[0] && astroData[0].moon_phase == 'Full Moon'">
                            <img src="../assets/lunar_icons/full-moon.svg" alt="full-moon" class="lunar">
                        </span>
                        <span v-if="astroData[0] && astroData[0].moon_phase == 'Waxing Gibbous'">
                            <img src="../assets/lunar_icons/waxing-gibbous.svg" alt="waxing gibbous" class="lunar">
                        </span>
                        <span v-if="astroData[0] && astroData[0].moon_phase == 'First Quarter'">
                            <img src="../assets/lunar_icons/first-quarter.svg" alt="first-quater" class="lunar-quarter">
                        </span>
                        <span v-if="astroData[0] && astroData[0].moon_phase == 'Waxing Crescent'">
                            <img src="../assets/lunar_icons/waxing-crescent.svg" alt="waxing-crescent" class="lunar">
                        </span>
                        <span v-if="astroData[0] && astroData[0].moon_phase == 'New Moon'">
                            <img src="../assets/lunar_icons/new-moon.svg" alt="new-moon" class="lunar">
                        </span>
                        <span v-if="astroData[0] && astroData[0].moon_phase == 'Waning Crescent'">
                            <img src="../assets/lunar_icons/waning-crescent.svg" alt="waning-crescent" class="lunar">
                        </span>
                        <span v-if="astroData[0] && astroData[0].moon_phase == 'Third Quarter'">
                            <img src="../assets/lunar_icons/third-quarter.svg" alt="third-quarter" class="lunar-quarter">
                        </span>
                        <span v-if="astroData[0] && astroData[0].moon_phase == 'Waning Gibbous'">
                            <img src="../assets/lunar_icons/waning-gibbous.svg" alt="waning-gibbous" class="lunar">
                        </span>
                    </h4>
                    <h4>Moon Illumination: <span v-if="astroData[0]">{{astroData[0].moon_illumination}}</span><span v-else>loading...</span></h4>
                </div>
                <!-- Tomorrow -->
                <div class="text card">
                    <h4>Date: <span v-if="astroData[1]">{{astroData[1].date}}</span><span v-else>loading...</span></h4>
                    <h4>Sunrise: <span v-if="astroData[1]">{{astroData[1].sunrise}}</span><span v-else>loading...</span></h4>
                    <h4>Sunset: <span v-if="astroData[1]">{{astroData[1].sunset}}</span><span v-else>loading...</span></h4>
                    <h4>Moonrise: <span v-if="astroData[1]">{{astroData[1].moonrise}}</span><span v-else>loading...</span></h4>
                    <h4>Moonset: <span v-if="astroData[1]">{{astroData[1].moonset}}</span><span v-else>loading...</span></h4>
                    <h4>Moon Phase: <span v-if="astroData[1]">{{astroData[1].moon_phase}}</span><span v-else>loading...</span>
                        <span v-if="astroData[1] && astroData[1].moon_phase == 'Full Moon'">
                            <img src="../assets/lunar_icons/full-moon.svg" alt="full-moon" class="lunar">
                        </span>
                        <span v-if="astroData[1] && astroData[1].moon_phase == 'Waxing Gibbous'">
                            <img src="../assets/lunar_icons/waxing-gibbous.svg" alt="waxing gibbous" class="lunar">
                        </span>
                        <span v-if="astroData[1] && astroData[1].moon_phase == 'First Quarter'">
                            <img src="../assets/lunar_icons/first-quarter.svg" alt="first-quater" class="lunar-quarter">
                        </span>
                        <span v-if="astroData[1] && astroData[1].moon_phase == 'Waxing Crescent'">
                            <img src="../assets/lunar_icons/waxing-crescent.svg" alt="waxing-crescent" class="lunar">
                        </span>
                        <span v-if="astroData[1] && astroData[1].moon_phase == 'New Moon'">
                            <img src="../assets/lunar_icons/new-moon.svg" alt="new-moon" class="lunar">
                        </span>
                        <span v-if="astroData[1] && astroData[1].moon_phase == 'Waning Crescent'">
                            <img src="../assets/lunar_icons/waning-crescent.svg" alt="waning-crescent" class="lunar">
                        </span>
                        <span v-if="astroData[1] && astroData[1].moon_phase == 'Third Quarter'">
                            <img src="../assets/lunar_icons/third-quarter.svg" alt="third-quarter" class="lunar-quarter">
                        </span>
                        <span v-if="astroData[1] && astroData[1].moon_phase == 'Waning Gibbous'">
                            <img src="../assets/lunar_icons/waning-gibbous.svg" alt="waning-gibbous" class="lunar">
                        </span>
                    </h4>
                    <h4>Moon Illumination: <span v-if="astroData[1]">{{astroData[1].moon_illumination}}</span><span v-else>loading...</span></h4>
                </div>
                <!-- Day after tomorrow -->
                <div class="text card">
                    <h4>Date: <span v-if="astroData[2]">{{astroData[2].date}}</span><span v-else>loading...</span></h4>
                    <h4>Sunrise: <span v-if="astroData[2]">{{astroData[2].sunrise}}</span><span v-else>loading...</span></h4>
                    <h4>Sunset: <span v-if="astroData[2]">{{astroData[2].sunset}}</span><span v-else>loading...</span></h4>
                    <h4>Moonrise: <span v-if="astroData[2]">{{astroData[2].moonrise}}</span><span v-else>loading...</span></h4>
                    <h4>Moonset: <span v-if="astroData[2]">{{astroData[2].moonset}}</span><span v-else>loading...</span></h4>
                    <h4>Moon Phase: <span v-if="astroData[2]">{{astroData[2].moon_phase}}</span><span v-else>loading...</span>
                        <span v-if="astroData[2] && astroData[2].moon_phase == 'Full Moon'">
                            <img src="../assets/lunar_icons/full-moon.svg" alt="full-moon" class="lunar">
                        </span>
                        <span v-if="astroData[2] && astroData[2].moon_phase == 'Waxing Gibbous'">
                            <img src="../assets/lunar_icons/waxing-gibbous.svg" alt="waxing gibbous" class="lunar">
                        </span>
                        <span v-if="astroData[2] && astroData[2].moon_phase == 'First Quarter'">
                            <img src="../assets/lunar_icons/first-quarter.svg" alt="first-quater" class="lunar-quarter">
                        </span>
                        <span v-if="astroData[2] && astroData[2].moon_phase == 'Waxing Crescent'">
                            <img src="../assets/lunar_icons/waxing-crescent.svg" alt="waxing-crescent" class="lunar">
                        </span>
                        <span v-if="astroData[2] && astroData[2].moon_phase == 'New Moon'">
                            <img src="../assets/lunar_icons/new-moon.svg" alt="new-moon" class="lunar">
                        </span>
                        <span v-if="astroData[2] && astroData[2].moon_phase == 'Waning Crescent'">
                            <img src="../assets/lunar_icons/waning-crescent.svg" alt="waning-crescent" class="lunar">
                        </span>
                        <span v-if="astroData[2] && astroData[2].moon_phase == 'Third Quarter'">
                            <img src="../assets/lunar_icons/third-quarter.svg" alt="third-quarter" class="lunar-quarter">
                        </span>
                        <span v-if="astroData[2] && astroData[2].moon_phase == 'Waning Gibbous'">
                            <img src="../assets/lunar_icons/waning-gibbous.svg" alt="waning-gibbous" class="lunar">
                        </span>
                    </h4>
                    <h4>Moon Illumination: <span v-if="astroData[2]">{{astroData[2].moon_illumination}}</span><span v-else>loading...</span></h4>
                </div>
            </div>
        <div class="btm"></div>    
    </div>
</template>

<script>
import NavComp from "@/components/NavComp";

export default {
    name: "LunarCalender",
    components: {
        NavComp
    },
    data() {
        return {
            //Homeposition
            homeLocation: {
                lat: 0,
                lon: 0,
            },
            astroData: {},
        }
    },
    methods: {
        async fetchLunarData(lat, lon) {
            let data = await fetch(`https://squaregarden.sharky.live/api/v1/weather/astro?lat=${lat}&lon=${lon}`);
            if(data.status === 200) {
                const text = await data.text();
                const json = await JSON.parse(text);
                this.astroData = json;
            }
        }
    },
    async beforeMount() {
        //Getting the current Homelocation
        this.homeLocation.lat = this.$store.getters["geolocation/getHomePosition"].lat;
        this.homeLocation.lon = this.$store.getters["geolocation/getHomePosition"].lon;
        //fetchLunarData
        await this.fetchLunarData(this.homeLocation.lat, this.homeLocation.lon);
    }
}
</script>

<style scoped>
.header {
    text-align: center;
}

h1 {
    color: #107869;
    font-size: 2.2rem;
    margin-top: 8px;
    font-weight: 600;
    margin-bottom: 50px;
}

.text {
    text-align: left;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
}

h4 {
    font-weight: 480;
    font-size: 1.2rem;
    margin-bottom: 8px;
}

img.lunar {
    width: 35px;
    height: 35px;
    margin: 5px;
}

img.lunar-quarter {
    width: 45px;
    height: 45px;
}

.btm {
    padding-bottom: 35px;
}

</style>